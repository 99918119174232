// HERO SECTION

.hero {
    position: relative;
    padding: 170px 0 50px;
    background-image: url('../img/placeholder.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .6s ease-in-out;
    &_overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($primary, .7);
        top: 0;
        left: 0;
    }
    &_slider {
        &-nav {
            margin: 30px 0;
            &_dot {
                margin-right: 20px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        &-slide {
            height: inherit;
            &_content {
                .title {
                    font-size: 40px;
                    line-height: 48px;
                }
                .text {
                    font-size: 17px;
                    line-height: 26px;
                }
                .btn {
                    width: 100%;
                }
            }
        }
    }
    &_thumbs {
        margin-top: 70px;
        position: relative;
        overflow: hidden;
        &-thumb {
            color: #fff;
            &_inner {
                cursor: pointer;
                position: relative;
                height: 233px;
                padding: 40px;
                background-repeat: no-repeat;
                background-size: cover;
                .overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba($primary, .4);
                    transition: all .3s ease-in-out;
                }
                .title {
                    z-index: 1000;
                    max-width: 213px;
                }
            }

            &.is-current {
                .hero_thumbs-thumb_inner {
                    color: $black;
                    .overlay {
                        background: rgba(#fff, .4);
                    }
                }
            }
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .hero {
        &_slider {
            &-slide {
                &_content {
                    .btn {
                        width: unset;
                    }
                }
            }
            &-nav {
                margin: 30px auto 30px 0;
            }
        }
    }
}

@media screen and (min-width: 767.98px) {
    .hero {
        &_slider {
            &-slide {
                &_content {
                    .title {
                        font-size: 46px;
                        line-height: 52px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 991.98px) {
    .hero {
        padding: 315px 0 60px;
        &_thumbs {
            margin-top: 167px;
        }
        &_slider {
            &-nav {
                &_dot {
                    margin-right: 0;
                    margin-bottom: 20px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            &-slide {
                &_content {
                    .title {
                        font-size: 58px;
                        line-height: 60px;
                    }
                    .text {
                        margin: 20px 0 50px;
                        max-width: 578px;
                        font-size: 19px;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}
