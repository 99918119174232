// PARTNERS SECTION

.partners {
    &_card {
        margin-top: 30px;
        .wrapper {
            border: 1px solid #C8C8C8;
            padding: 51px 0;
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 767.98px){
    .partners {
        .row {
            margin: 0 -15px;
        }
        &_header {
            padding: 0 15px;
        }
        &_card {
            padding: 0 15px;
            &[data-order="1"] {
                margin-top: 0;
            }
        }
    }
}

@media screen and (min-width: 991.98px){
    .partners {
        &_card {
            &[data-order="2"] {
                margin-top: 0;
            }
        }
    }
}