//  CONTACT SECTION

.contact {
    padding-top: 0;
    &_header {
        .text {
            margin: 30px 0 40px;
        }
    }

    &_map {
        margin-top: 40px;
        height: 350px;

        #map {
            width: 100%;
            height: 100%;
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 991.98px) {
    .contact {
        &_map {
            margin-top: 0;
            flex-grow: 1;
            height: 543px;
            margin-left: 30px;
        }
    }
}

@media screen and (min-width: 1399.98px) {
    .contact {
        &-wrapper {
            max-width: 500px;
        }

        &_map {
            max-width: 660px;
            height: 543px;
            margin-left: 0;
        }
    }
}