// FEEDBACK SECTION (SLIDER)

.feedback {
    padding: 80px 0 138px;
    &_header {
        color: #000810;
        .text {
            margin: 20px 0 40px;
            color: #202C38;
        }

        .btn {
            width: 100%;
        }
    }

    .tns {
        &-outer {
            position: relative;
        }
        &-nav {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            bottom: -44px;
            button {
                margin-right: 15px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &_slider {
        margin-top: 30px;

        &-slide {
            height: inherit;
            border: 1px solid #C8C8C8;

            &_wrapper {
                height: 100%;
                padding: 30px;

            }

            &_text {
                margin-bottom: 30px;
                color: #000810;
            }

            &_author {
                font-family: $accentFont;

                .avatar {
                    border-radius: 50%;
                    display: block;
                    object-fit: cover;
                    width: calc(174px / 3);
                    aspect-ratio: 1 / 1;
                    margin-right: 20px;
                }

                .name {
                    font-weight: 600;
                    margin-bottom: 5px;
                    font-size: 18px;
                    line-height: 23px;
                    color: #000810;
                }

                .company {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: $blackalt;
                }

                .name, .company {
                    display: block;
                }
            }
        }
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 575.98px) {
    .feedback {
        &_header {
            .btn {
                width: unset;
            }
        }
    }
}


@media screen and (min-width: 991.98px) {
    .feedback {
        padding: 180px 0;
        .tns-nav {
            left: 0;
            width: auto;
        }
    }
}

@media screen and (min-width: 1199.98px) {
    .feedback {
        &_slider {
            margin-top: 0;

            &-slide {
                &_wrapper {
                    padding: 60px;
                }
            }
        }
    }
}