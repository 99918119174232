//  TINY SLIDER CUSTOM DOTS STYLING

.tns-nav,
.hero_slider-nav {
    button {
        width: 12px;
        height: 12px;
        background: #E9E6E6;
        display: block;
        border-radius: 50%;
        position: relative;
        transition: all .2s ease-in-out;

        &:after {
            content: '';
            position: absolute;
            width: 22px;
            height: 22px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translateY(-50%) translateX(-50%);
            border: 1px solid transparent;
            transition: all .2s ease-in-out;
        }

        &.tns-nav-active {
            background: $primary;

            &:after {
                border-color: $primary;
            }
        }
    }
    &--alt {
        button {
            &.tns-nav-active {
                background: $accent;

                &:after {
                    border-color: $accent;
                }
            }
        }
    }
}

