// GALLERY SECTION

.gallery {
    &_header {
        margin-bottom: 30px;
    }
}

// ------ MEDIA QUERIES

@media screen and (min-width: 991.98px){
    .gallery {
        &_header {
            margin-bottom: 40px;
        }
    }
}
