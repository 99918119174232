// BASE
@import "bootstrap/dist/css/bootstrap-grid";
@import "base/base";
@import "base/mixins";
@import "base/variables";
@import "base/keyframes";
@import "global/global";
@import "../fonts/icomoon/style.css";
// BASE

@import "blocks/header";
@import "blocks/partners";
@import "pages/index2/about";
@import "pages/index2/advantages";
@import "pages/index2/gallery";
@import "pages/gallery/main-grid";
@import "blocks/process";
@import "../css/progress-tracker.css";
@import "pages/index2/hero";
@import "blocks/feedback-slider";
@import "blocks/slider/dots";
@import "blocks/contact-data";
@import "pages/index2/contact";
@import "blocks/footer";

body {
    color: #162739;
}